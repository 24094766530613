function logitFAQ() {
  const btns = document.querySelectorAll(".faq-btn");
  if (btns.length > 0) {
    const answers = [...document.querySelectorAll(".answer")];
    const firstBtn = document.querySelector(".faq-btn");
    const firstAnswer = document.querySelector(".answer");

    firstBtn.classList.add("active");
    firstAnswer.classList.add("show");

    btns.forEach(btn => {
      btn.addEventListener("click", function(e) {
        e.preventDefault();
        const choice = e.target.dataset.question;
        removeActiveState();
        e.target.classList.add("active");
        showAnswer(choice);
      });
    });

    function removeActiveState() {
      btns.forEach(btn => {
        btn.classList.remove("active");
      });
    }

    function showAnswer(whichAnswer) {
      answers.forEach(answer => {
        if (answer.dataset.answer == whichAnswer) {
          answers[whichAnswer].classList.add("show");
        } else {
          answer.classList.remove("show");
        }
      });
    }

    /* Wyświetlanie FAQ na urządzeniach mobilnych */
    const mobileBtns = document.querySelectorAll(".faq-btn-mobile");
    const mobileAnswers = document.querySelectorAll(".answer-mobile");

    function hideMobileAnswers() {
      mobileAnswers.forEach(answer => {
        answer.style.display = "none";
      });
    }
    hideMobileAnswers();

    mobileBtns.forEach(mobileBtn => {
      mobileBtn.addEventListener("click", function(e) {
        e.preventDefault();
        hideMobileAnswers();
        const choice = e.target.dataset.question;
        mobileAnswers[choice].style.display = "block";
      });
    });
  }
}

logitFAQ();
