import $ from "jquery";

export default class SliderThumbnails {
  constructor(slider) {
    this.slider = slider;
    this.currentSliderItem = null;
    this.slogansContent = document.querySelectorAll(
      ".slider-thumbs-content__slogan"
    );
    this.imagesUrls = document.querySelectorAll(
      ".slider-thumbs-content__image"
    );
    this.thumbH5 = document.querySelector(".thumb__info h5");
    this.thumbImg = document.querySelector(".thumb__preview");
    this.sliderBtns = [...document.querySelectorAll(".slick-dots li button")];
    this.count = 1;
    this.slogans = [];
    this.images = [];
    this.init();
  }

  init() {
    this.fillContent();
    this.setActiveState();
    this.slider.on("beforeChange", (event, slick, currentSlide) =>
      this.jumpTo(currentSlide + 1)
    );
    this.slider.on("afterChange", () => this.setActiveState());
  }

  removeActiveState() {
    const allSlides = document.querySelectorAll(".active-bg");
    allSlides.forEach(slide => {
      slide.classList.remove("active-bg");
    });
  }

  setActiveState() {
    this.currentSliderItem = document.querySelector(
      "div.slick-active .slider__item"
    );
    this.removeActiveState();
    this.currentSliderItem.classList.add("active-bg");
  }

  updateThumb() {
    this.thumbH5.innerText = this.slogans[this.count];
    this.thumbImg.style.backgroundImage =
      "url('" + this.images[this.count] + "')";
  }

  jumpTo(whichSlide) {
    if (whichSlide == this.sliderBtns.length) {
      this.count = 1;
    } else if (whichSlide == this.sliderBtns.length - 1) {
      this.count = 0;
    } else {
      this.count = whichSlide + 1;
    }
    this.updateThumb();
  }

  fillContent() {
    this.slogansContent.forEach(slogan => {
      this.slogans.push(slogan.innerText);
    });

    this.imagesUrls.forEach(image => {
      this.images.push(image.innerText);
    });

    this.updateThumb();
  }
}
