// https://github.com/kenwheeler/slick

import SliderThumbnails from "./sliderThumbnails";
import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".slider__items")
};

sliders.home.on("init", function(slick) {
  new SliderThumbnails(sliders.home);
});

sliders.home.slick({
  autoplay: true,
  autoplaySpeed: 6000,
  dots: true,
  appendDots: $(".slider__dots"),
  arrows: false,
  infinite: true,
  pauseOnHover: false,
  customPaging: function(slider, i) {
    return "<button></button>";
  }
});
